<template>
  <div>
    <el-row>
      <div class="wraper" v-wechat-title="this.goodsinfo.name+ '-大药房订货系统'">
        <ShopHead :productName="productName"></ShopHead>
        <ShopGalss class="glass" v-if="renderFlag" :goodsinfo="goodsinfo"></ShopGalss>
        <GoodsInfoFreeMail class="info" v-if="renderFlag" :goodsinfo="goodsinfo"></GoodsInfoFreeMail>
      </div>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="AssociatedFlag">
        <AssociatedDrug class="AssociatedDrug" v-if="renderFlag" :goodsinfo="goodsinfo" @ShowFun="AssociatedFun">
        </AssociatedDrug>
      </el-col>
      <el-col :span="24">
        <DetailInfo class="detailInfo" v-if="renderFlag" :goodsinfo="goodsinfo"></DetailInfo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    freeMailDetail
  } from "api/activity.js";
  // import Qs from 'qs'
  const ShopGalss = () => import("components/product/ShopGalss.vue");
  const GoodsInfoFreeMail = () => import("components/product/GoodsInfoFreeMail.vue");
  const ShopHead = () => import("components/product/ShopHead.vue");
  const DetailInfo = () => import("components/product/DetailInfo.vue");
  const AssociatedDrug = () => import("components/product/AssociatedDrug.vue");
  export default {
    name: "FreeMailDetail",
    inject: ['reload'],
    data() {
      return {
        productName: '',
        renderFlag: false,
        AssociatedFlag: true,
        goodsinfo: "",
        parameterValues: "",
      };
    },
    components: {
      ShopGalss,
      GoodsInfoFreeMail,
      ShopHead,
      DetailInfo,
      AssociatedDrug,
    },
    watch: {
      //   监听路径变化，获取数据并给商品数据赋值
      "$route.query.productId": function () {
        this.reload();
      },
    },
    created() {
      this.getProductDetail();
    },
    methods: {
      AssociatedFun() {
        this.AssociatedFlag = false
      },
      getProductDetail() {
        const params = this.$route.query;
        // console.log('params: ', params);
        // 显示loading
        this.$loading({
          fullscreen: true,
          background: '#ffffff45'
        })

        freeMailDetail(params).then((res) => {
          console.log('小额包邮详情', res.data.data);
          if (res.data.code == 400) {
            this.$message.error(res.data.msg);
            let that = this
            setTimeout(function () {
              that.$router.push({
                name: "Home"
              })
            }, 500);
          }
          this.productName = res.data.data.name
          this.goodsinfo = res.data.data;
          this.renderFlag = true;
          this.AssociatedFlag = true;
          this.parameterValues = res.data.data;
          // 关闭loading
          this.$loading().close();
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  @import "~style/index.less";

  .el_main {
    background: #fff;
  }

  .wraper {
    margin-top: 20px;

    .glass {
      float: left;
    }

    .info {
      float: left;
      margin-left: 20px;
      width: calc(100% - 458px);
    }

    .detailInfo {
      width: 1200px;
    }
  }
</style>